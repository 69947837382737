import { request, requestPost } from "@/plugin/axios.js";
import moment from 'moment'
// 总览数据统计
export const getOverview = () => {
    return request({
        url: "/charging-admin/open/largeScreen/overview",
    });
};
// 经营概况
export const queryPerformance = (data) => {
    return requestPost({
        url: "/charging-admin/open/largeScreen/queryPerformance",
        data
    });
};
// 站点管理
export const queryList = () => {
    return requestPost({
        url: "/charging-admin/open/largeScreen/list",
        data: {
            trendTime: 3
        }
    });
};
export const queryOperationEfficiencyt = () => {
    return requestPost({
        url: "/charging-admin/open/largeScreen/queryOperationEfficiency",
        data: {
            dateTime: 1,
            date: moment().format("YYYY-MM-DD"),
            startDate: moment().subtract('days', 7).format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
        }
    });
};
