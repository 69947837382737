<template>
  <div class="content-wrap">
    <IconBox v-for="(item, index) in dataList" :key="index" v-bind="item">
    </IconBox>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
const props = defineProps({
  data: {
    type: Array,
    default: () => {
      return [0, 0, 0, 0];
    },
  },
});
const dataList = computed(() => {
  return [
    {
      icon: "1-1",
      title: "站点数量",
      unit: "个",
      number: props.data[0],
    },
    {
      icon: "1-2",
      title: "桩数量",
      unit: "个",
      number: props.data[1],
    },
    {
      icon: "1-3",
      title: "直流枪数量",
      unit: "个",
      number: props.data[2],
    },
    {
      icon: "1-4",
      title: "交流枪数量",
      unit: "个",
      number: props.data[3],
    },
  ];
});
</script>

<style lang="less" scoped>
.content-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 22px;
  padding-top: 10px;
  box-sizing: border-box;
}
</style>
