<template>
  <div class="icon-box flex-ai-center" :style="style">
    <svg-icon :iconClass="props.icon" :svgStyle="props.iconStyle" />
    <div class="box-text">
      <div class="title">{{ props.title }}</div>
      <div class="val">
        <count-to
          v-if="props.move"
          class="number"
          :startVal="0"
          :decimals="props.number > 10000 ? 2 : props.decimals"
          :endVal="props.number > 10000 ? props.number / 10000 : props.number"
          :duration="4000"
          ref="numberRef"
        ></count-to>
        <div class="number" v-else>
          {{ props.number > 10000 ? props.number / 10000 : props.number }}
        </div>
        <div class="unit">
          {{ props.number > 10000 ? `万${props.unit}` : props.unit }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineExpose } from "vue";
const numberRef = ref(null);
const props = defineProps({
  style: {
    type: Object,
    default() {
      return {
        // width: "50%",
      };
    },
  },
  icon: {
    type: String,
    default: "1-1",
  },
  iconClass: {
    type: String,
    default: "icon",
  },
  iconStyle: {
    type: Object,
    default() {
      return {
        width: "56px",
        height: "62px",
      };
    },
  },
  title: {
    type: String,
    default: "",
  },
  number: {
    default: "0000",
  },
  numberSize: {
    type: String,
    default: "32",
  },
  unit: {
    type: String,
    default: "单位",
  },
  move: {
    type: Boolean,
    default: true,
  },
  prefix: {
    type: String,
    default: "",
  },
  isdecimal: {
    type: Boolean,
    default: false,
  },
  decimals: {
    type: Number,
    default: 0,
  },
});
// watchEffect(() => {
//   if (props.number) {
//     // 当有值传来时执行下面的操作
//     numberRef.value.start();
//   }
// });
function init() {
  console.log("执行方法");
  numberRef.value.start();
}
defineExpose({
  init,
});
</script>

<style lang="less" scoped>
.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex-shrink: 0;
  }

  .box-text {
    min-width: 50%;
    margin-left: 12px;
    .number {
      font-family: YouSheBiaoTiHei;
      font-size: 26px;
      font-weight: normal;
      line-height: 30px;
      letter-spacing: 0px;
      color: #16d7ff;
    }

    .title {
      font-family: YouSheBiaoTiHei;
      font-size: 18px;
      font-weight: lighter;
      line-height: 24.07px;
      letter-spacing: 0px;

      color: #f7faff;
    }
    .val {
      display: flex;
      align-items: last baseline;
      .unit {
        font-family: YouSheBiaoTiHei;
        font-size: 12px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0px;
        margin-left: 4px;
        color: #f7faff;
      }
    }
  }
}

.val {
  display: flex;
  align-items: last baseline;
}
</style>
