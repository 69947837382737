<template>
  <div class="tab-box">
    <div
      :class="value == item.value ? 'buttona' : 'buttonn'"
      :style="{ height: '29px', width }"
      @click="changeVal(item)"
      v-for="(item, index) in tabList"
      :key="index"
    >
      <span>{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: () => {
        return "";
      },
    },
    width: {
      type: String,
      default: () => {
        return "62px";
      },
    },
    tabList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    changeVal(item) {
      this.$emit("change", item);
    },
  },
};
</script>

<style lang="less" scoped>
.tab-box {
  display: flex;
  align-items: center;
  > div {
    cursor: pointer;
    margin-left: 12px;
    position: relative;
    > span {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      color: #fff;
      font-size: 12px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .buttona {
    background: url("~@/assets/images/button-选中.png");
    background-size: 100% 100%;
  }
  .buttonn {
    background: url("~@/assets/images/button-未选中.png");
    background-size: 100% 100%;
  }
}
</style>
