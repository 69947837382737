<template>
  <div class="content-wrap">
    <div class="tabbtn">
      <TabBtn :tabList="tabList" :value="tabVal" @change="change"></TabBtn>
    </div>
    <div class="icon-wrap">
      <IconBox v-for="(item, index) in dataList" :key="index" v-bind="item">
      </IconBox>
    </div>
    <div ref="initChart" class="chart-box"></div>
  </div>
</template>

<script setup>
const tabList = [
  { value: 1, title: "近7天" },
  { value: 2, title: "近30天" },
  { value: 3, title: "近12月" },
];
import TabBtn from "@/components/TabBtn/index.vue";
import * as echarts from "echarts";
import { ref, onMounted, computed, onUnmounted } from "vue";
import { queryPerformance } from "@/api/index.js";
const initChart = ref();
const tabVal = ref(1);
const timer = ref(null);
const valList = ref([0, 0, 0, 0]);
const dataList = computed(() => {
  return [
    {
      icon: "1-5",
      title: "累计充电电量",
      unit: "度",
      decimals: 2,
      number: valList.value[0],
    },
    {
      icon: "1-6",
      title: "累计碳减排",
      decimals: 2,
      unit: "tCO2e",
      number: valList.value[1],
    },
    {
      icon: "1-7",
      title: "累计订单量",
      unit: "单",
      number: valList.value[2],
    },
    {
      icon: "1-8",
      title: "累计充电收入",
      decimals: 2,
      unit: "元",
      number: valList.value[3],
    },
  ];
});
onMounted(() => {
  chartInit(
    ["充电量", "订单总额", "充电服务费"],
    ["度", "元", "元"],
    [
      {
        data: [],
        key: "chargePqList",
        name: "充电量",
      },
      {
        data: [],
        key: "chargeAmtList",
        name: "订单总额",
      },
      {
        data: [],
        key: "serviceAmtList",
        name: "充电服务费",
      },
    ],
    []
  );
  getQueryPerformance();
  timer.value = setInterval(() => {
    if (tabVal.value == 3) {
      tabVal.value = 1;
    } else {
      tabVal.value++;
    }
    getQueryPerformance();
  }, 10000);
});
onUnmounted(() => {
  clearInterval(timer.value);
  timer.value = null;
});
const change = (val) => {
  tabVal.value = val.value;
  getQueryPerformance();
};
async function getQueryPerformance() {
  const [res, err] = await queryPerformance({
    trendTime: tabVal.value,
  });
  if (err) return;
  const {
    dateList = [],
    chargePqList = [],
    chargeAmtList = [],
    serviceAmtList = [],
    totalChargePq = 0,
    accumulatedCarbonReduction = 0,
    accumulatedOrderQuantity = 0,
    totalChargeAmt = 0,
  } = res?.data;
  valList.value = [
    totalChargePq,
    accumulatedCarbonReduction,
    accumulatedOrderQuantity,
    totalChargeAmt,
  ];
  const series = [
    {
      data: chargePqList,
      key: "chargePqList",
      name: "充电量",
    },
    {
      data: chargeAmtList,
      key: "chargeAmtList",
      name: "订单总额",
    },
    {
      data: serviceAmtList,
      key: "serviceAmtList",
      name: "充电服务费",
    },
  ];
  chartInit(
    ["充电量", "订单总额", "充电服务费"],
    ["度", "元", "元"],
    series,
    dateList
  );
}
const chartInit = (legend, unit, seriesData, xAxis) => {
  let option = {
    grid: {
      right: "8%",
      left: 92,
      bottom: 50,
      top: 96,
    },
    xAxis: {
      type: "category",
      data: xAxis,
      axisLabel: {
        color: "#fff",
        fontSize: 10,
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: function (info) {
        let str = `<div style="width:160px;"><div style="text-align: left;color: #E0F0FF;font-size:16px;padding-left:6px;margin-bottom:8px;margin-top:4px" >${info[0].name}</div>`;
        info.forEach((item) => {
          str += `<div style="
                  height: 26px;
                  display: flex;
                  justify-content: space-between;
                  padding: 8px;
                  border-radius: 4px;
                  margin: 4px 0; align-items: center;"
        >
        <div style="
                  display: flex;
          align-items: center;
            ">
            <span style="width:12px;height:12px;border-radius:50%;display: inline-block; background:${item.borderColor}"></span>
        <span style=" font-size: 14px;line-height:14px;margin-left:5px">${item.seriesName}</span>
          </div>
          <span style="color: ${item.borderColor}; font-size: 16px;font-family: D-DIN;">${item.value}</span></div></div>`;
        });
        return str;
      },
      backgroundColor: "rgba(18, 26, 45, 0.9)",
      borderColor: "rgba(0, 221, 255, 1)",
      textStyle: {
        color: "#E0F0FF",
        fontSize: 12,
      },
    },
    legend: {
      top: 38,
      itemHeight: 8,
      itemWidth: 19,
      data: legend, // 图例的文本内容，与系列的name对应
      textStyle: {
        color: "#ffffff",
        opacity: 0.8,
        fontSize: 10,
      },
      // 其他配置...
    },
    yAxis: [
      {
        type: "value",
        name: "充电量",
        nameTextStyle: {
          color: "#fff",
          fontSize: 10,
          padding: [0, 20, 0, 0],
        },
        position: "left",
        alignTicks: true,
        offset: 40,
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            width: 1,
            // 使用深浅的间隔色
            color: ["#566471", "#566471"],
          },
        },
        //坐标值标注
        axisLabel: {
          show: true,
          textStyle: {
            color: "#fff",
            fontSize: 10,
          },
        },
      },
      {
        type: "value",
        name: "订单总额",
        nameTextStyle: {
          color: "#fff",
          fontSize: 10,
          padding: [0, 16, 0, 0],
        },
        position: "left",
        alignTicks: true,
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            width: 1,
            // 使用深浅的间隔色
            color: ["#566471", "#566471"],
          },
        },
        //坐标值标注
        axisLabel: {
          show: true,
          textStyle: {
            color: "#fff",
            fontSize: 10,
          },
        },
      },
      {
        type: "value",
        nameTextStyle: {
          color: "#fff",
          fontSize: 10,
        },
        name: "充电服务费",
        position: "right",
        alignTicks: true,
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            width: 1,
            // 使用深浅的间隔色
            color: ["#566471", "#566471"],
          },
        },
        //坐标值标注
        axisLabel: {
          show: true,
          textStyle: {
            color: "#fff",
            fontSize: 10,
          },
        },
      },
    ],
    series: [
      {
        name: seriesData[0].name,
        data: seriesData[0].data,
        type: "line",
        smooth: true,
        yAxisIndex: 0,
        symbol: "circle", //标记的图形为实心圆
        symbolSize: 6, //标记的大小
        lineStyle: {
          color: "#35FFF4", //线条颜色
        },
        itemStyle: {
          color: "#fff", // 圆点背景色
          borderColor: "#35FFF4", //圆点透明 边框
          borderWidth: 2,
        },
      },
      {
        name: seriesData[1].name,
        data: seriesData[1].data,
        type: "line",
        smooth: true,
        yAxisIndex: 1,
        symbol: "circle", //标记的图形为实心圆
        symbolSize: 6, //标记的大小
        lineStyle: {
          color: "#088BFF", //线条颜色
        },
        itemStyle: {
          color: "#fff", // 圆点背景色
          borderColor: "#088BFF", //圆点透明 边框
          borderWidth: 2,
        },
      },
      {
        name: seriesData[2].name,
        data: seriesData[2].data,
        type: "line",
        yAxisIndex: 2,
        symbol: "circle", //标记的图形为实心圆
        symbolSize: 6, //标记的大小
        smooth: true,
        lineStyle: {
          color: "#ECA850", //线条颜色
        },
        itemStyle: {
          color: "#fff", // 圆点背景色
          borderColor: "#ECA850", //圆点透明 边框
          borderWidth: 2,
        },
      },
    ],
  };
  echarts.init(initChart.value).dispose();
  echarts.init(initChart.value).setOption(option);
};
</script>

<style lang="less" scoped>
.content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  .tabbtn {
    position: absolute;
    top: -30px;
    right: 0;
  }
  .icon-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 22px;
    padding-top: 18px;
    box-sizing: border-box;
  }
  .chart-box {
    height: 300px;
    width: 100%;
  }
}
</style>
