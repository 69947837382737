<template>
  <div class="chart-container" :style="style">
    <div class="map-container">
      <div class="map-title">
        <div class="map-title-container">
          <div class="map-title-container-left">
            <img src="@/assets/images/titlebot.png" />
            <span>{{ title }}</span>
          </div>
          <div class="map-title-container-right">
            <slot name="titleRight"></slot>
          </div>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
  name: "ChartContainer",
  props: {
    width: {
      type: Number,
    },
    title: {
      type: String,
    },
    height: {
      type: Number,
    },
  },
  setup: (props) => {
    const width = ref(props.width);
    const height = ref(props.height);
    const style = computed(() => {
      return {
        ...(width.value ? { width: `${width.value}px` } : {}),
        ...(height.value ? { height: `${height.value}px` } : {}),
      };
    });
    return {
      style,
    };
  },
});
</script>

<style scoped lang="less">
.chart-container {
  position: relative;
  width: 500px;
  height: 224px;
  margin-bottom: 20px;
  background: radial-gradient(
      6% 50% at 50% 100%,
      rgba(23, 217, 241, 0.1) 0%,
      rgba(8, 255, 247, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(23, 217, 241, 0.02) 0%,
      rgba(23, 217, 241, 0.06) 98%
    );
  .map-title {
    position: absolute;
    left: 0px;
    top: 0px;
    // width: 100%;
    height: 50px;
    /* 自动布局 */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    background-image: url("~@/assets/images/titlebg.png");
    width: 100%;
    height: 37px;
    background-size: 100% 100%;
    z-index: -1;
  }

  .map-title-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 17px;
    position: relative;
    top: -2px;
    z-index: 5;
    font-weight: normal;
    font-family: YouSheBiaoTiHei;
    font-size: 24px;
    line-height: 100%;
    height: 37px;
    /* FFFFFF */
    color: #ffffff;

    .map-title-container-left {
      display: flex;
      align-items: center;
      line-height: 100%;
    }

    img {
      height: 28px;
      width: 28px;
      margin-top: -1px;
      margin-left: -10px;
    }
  }

  .map-container {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 50px 20px 10px 20px;
    box-sizing: border-box;
    align-self: stretch;
    background-color: rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    z-index: 1;
  }
}
</style>
