import { onMounted, onUnmounted } from "vue";

export default function useAddEventListener(name: string, fn: () => void) {
  onMounted(() => {
    window.addEventListener(name, fn);
  });
  onUnmounted(() => {
    window.removeEventListener(name, fn);
  });
}
