
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "Timer",
  setup: () => {
    const timeLeft = ref("--:--:--");
    const timeRight = ref("--:--:--");
    const week = ref("--");
    let timer: number;
    onMounted(() => {
      formTimer();
      timer = setInterval(formTimer, 1000);
    });
    onUnmounted(() => {
      clearInterval(timer);
    });
    function getWeek(num: number) {
      const weeks = ["日", "一", "二", "三", "四", "五", "六"];
      week.value = `星期${weeks[num]}`;
    }
    function formTimer() {
      const now = dayjs();
      const weekNum = dayjs().day();
      timeLeft.value = now.format("HH:mm:ss");
      timeRight.value = now.format("YYYY-MM-DD");
      getWeek(weekNum);
    }
    return {
      timeRight,
      timeLeft,
      week,
    };
  },
});
