<template>
  <div class="PowerStation">
    <div class="echart">
      <img src="../../../assets/images/round-bg.png" />
      <div ref="initChart" class="chart-box"></div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from "echarts";
import { reactive, ref, nextTick, watch, defineProps, computed } from "vue";
const colorList = [
  "#00FFAA",
  "#FDE9A6",
  "#38EBFF",
  "#FF6969",
  "#646BFE",
  "#2386FF",
];
const props = defineProps({
  data: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
const initChart = ref();
const data = reactive({
  chartIndex: 0,
  timer: null,
});
watch(
  () => props.data,
  () => {
    nextTick(() => {
      if (props.data.length) {
        chartInit();
      }
    });
  }
);
const chartInit = () => {
  clearInterval(data.timer);
  let objData = {};
  props.data.forEach((item) => {
    objData[item.areaName] = item.areaStationNum;
  });
  let option = {
    color: colorList,
    grid: {
      left: 0,
      top: 10,
      right: 0,
      bottom: 0,
    },
    tooltip: {
      trigger: "item",
      textStyle: {
        color: "#E0F0FF",
        fontSize: 13,
      },
      backgroundColor: "rgba(18, 26, 45, 0.9)",
      borderColor: "rgba(0, 221, 255, 1)",
    },
    legend: {
      orient: "vertical",
      textStyle: {
        color: "#fff",
        fontSize: 13,
      },
      itemWidth: 11,
      itemHeight: 11,
      icon: "rect",
      formatter: function (name) {
        let item = props.data.filter((item) => item.areaName == name)[0];
        return `${item.areaName}    ${item.areaStationNum}`;
      },
      itemGap: 14,
      right: "20%",
      top: "20%", //居右显示
    },
    series: [
      {
        name: "",
        type: "pie",
        center: ["28%", "59%"],
        radius: ["40%", "52%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        itemStyle: {
          normal: {
            // borderColor: '#fff',
            borderWidth: 2,
          },
          emphasis: {
            // borderColor: '#fff',
            borderWidth: 4,
          },
        },
        labelLine: {
          show: false,
        },
        data: props.data.map((item) => {
          return {
            value: item.areaStationNum,
            name: item.areaName,
          };
        }),
      },
    ],
  };
  if (echarts.init(initChart.value) != null) {
    echarts.init(initChart.value).dispose();
    echarts.init(initChart.value).setOption(option);
  }
  // 定时循环高亮不同的数据
  data.timer = setInterval(() => {
    data.chartIndex = (data.chartIndex + 1) % props.data.length;
    if (echarts.init(initChart.value) != null) {
      echarts.init(initChart.value).dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: data.chartIndex,
      });
      echarts.init(initChart.value).dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex:
          data.chartIndex === 0 ? props.data.length - 1 : data.chartIndex - 1,
      });
    }
  }, 2000);
};
</script>

<style lang="less" scoped>
.PowerStation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  height: 100%;
  box-sizing: border-box;

  .echart {
    width: 100%;
    height: 300px;
    > img {
      position: absolute;
      height: 168px;
      width: 207px;
      transform: translate(-50%, -50%);
      left: 28%;
      top: 60%;
    }

    .chart-box {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 100%;
      height: 240px;
    }
  }
}
</style>
