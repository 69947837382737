import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import home from "../views/home/index.vue";
// import SmartMicrogrid from "../views/SmartMicrogrid/index.vue";
// import IntelligentDecharging from "../views/intelligentDecharging/index.vue";
// import MapMark from "../views/MapMark/index.vue";
// import stationMonitoring from "../views/stationMonitoring/index.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    // 智慧停冲
    path: "/home",
    name: "home",
    component: home,
  },
  // {
  //   path: "/mapMark",
  //   name: "MapMark",
  //   component: () => import("@/views/MapMark/index.vue"),
  // },
  // {
  //   // 整体态势
  //   path: "/OverallSituation",
  //   name: "OverallSituation",
  //   component: OverallSituation,
  // },
  // {
  //   // 智慧微网
  //   path: "/SmartMicrogrid",
  //   name: "SmartMicrogrid",
  //   component: SmartMicrogrid,
  // },

  // {
  //   //  智慧停充中间 地图
  //   path: "/mapMark",
  //   name: "MapMark",
  //   component: MapMark,
  // },
  // {
  //   //  整体态势 - 场站监控 iframe内嵌
  //   path: "/stationMonitoring",
  //   name: "stationMonitoring",
  //   component: stationMonitoring,
  // },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),

  routes,
});

export default router;
