import { createStore } from "vuex";

export default createStore({
  state: {
    scaleY: 1,
    scaleX: 1,
    airportCode: "",
  },
  mutations: {
    updateScaleY(store, payload: number) {
      store.scaleY = payload;
    },
    updateScaleX(store, payload: number) {
      store.scaleX = payload;
    },
    updateAirportCode(store, payload: string) {
      store.airportCode = payload;
    },
  },
  actions: {},
  modules: {},
});
