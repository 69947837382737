
import {
  defineComponent,
  PropType,
  onMounted,
  onUnmounted,
  ref,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import useAddEventListener from "@/hooks/useAddEventListener";
export default defineComponent({
  name: "Container",
  setup(props, context) {
    const store = useStore();
    const containerRef = ref<HTMLDivElement>();
    const width = 1920;
    const height = 1080;
    let realWidth;
    let realHeight;
    useAddEventListener("resize", async () => {
      await nextTick();
      await updateDom();
    });
    onMounted(async () => {
      await nextTick();
      updateDom();
    });
    function updateDom() {
      realWidth = document.body.clientWidth;
      realHeight = document.body.clientHeight;
      containerRef.value!.style.width = `${width}px`;
      containerRef.value!.style.height = `${height}px`;
      const scaleX = realWidth / width;
      const scaleY = realHeight / height;
      store.commit("updateScaleY", scaleY);
      store.commit("updateScaleX", scaleX);
      containerRef.value!.style.transform = `scale(${scaleX}, ${scaleY})`;
    }
    return {
      containerRef,
      width,
      height,
    };
  },
});
