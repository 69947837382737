import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const service = axios.create({
  baseURL: "http://api-test.nengjkj.com",
  timeout: 10000, //超时配置
  // 自定义配置覆盖基本配置
});

service.interceptors.request.use(
  (config) => {
    // const basicAuth = Buffer.from(`wwww`).toString('base64');
    // // 添加认证信息到头部
    // config.headers.Authorization = `Basic ${basicAuth}`;
    return config;
  },
  (error) => {
    console.log("请求拦截错误:", error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  (error) => {
    console.log("响应拦截错误:", error);
    return Promise.reject(error);
  }
);

export const request = ({ url, params }) => {
  return new Promise((resolve, reject) => {
    service({
      method: "get",
      url: url,
      params: params,
    })
      .then((res) => {
        resolve([res, null]);
      })
      .catch((err) => {
        resolve([null, err]);
      });
  });
};

export const requestPost = ({ url, data }) => {
  return new Promise((resolve, reject) => {
    service({
      method: "post",
      url: url,
      data: data,
    })
      .then((res) => {
        resolve([res, null]);
      })
      .catch((err) => {
        resolve([null, err]);
      });
  });
};

