// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function preloadImg({ src, width, height }) {
    return new Promise((resolve) => {
        var img = new Image();
        img.src = src;
        img.width = width;
        img.height = height;
        img.onload = function () {
            resolve(img);
        };
    });
}