<template>
  <div class="content-wrap">
    <div class="box" v-for="(item, index) in dataList" :key="index">
      <div :class="['top', `bg${index + 1}`]">
        <div class="text">
          <div>{{ item.title }}</div>
          <div class="percent" :style="{ color: item.strokeColor }">
            {{ item.percent || 0 }}%
          </div>
        </div>
        <a-progress
          :trailColor="item.trailColor"
          stroke-linecap="square"
          :strokeColor="item.strokeColor"
          :percent="item.percent"
          type="dashboard"
        />
      </div>
      <div class="bot" :style="{ color: item.strokeColor }">
        {{ `${item.val}${item.unit}` }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
const props = defineProps({
  data: {
    type: Array,
    default: () => {
      return [0, 0, 0, 0, 0];
    },
  },
});
const allNum = computed(() => {
  return props.data.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
});
const dataList = computed(() => {
  return [
    {
      strokeColor: "rgba(0, 255, 170, 1)",
      trailColor: "rgba(0, 255, 170, 0.2)",
      percent: ((props.data[0] / allNum.value) * 100).toFixed(0),
      val: props.data[0],
      unit: "座",
      title: "运营",
    },
    {
      strokeColor: "rgba(105, 183, 255, 1)",
      trailColor: "rgba(105, 183, 255, 0.2)",
      percent: ((props.data[1] / allNum.value) * 100).toFixed(0),
      val: props.data[1],
      unit: "座",
      title: "建设中",
    },
    {
      strokeColor: "rgba(255, 148, 77, 1)",
      trailColor: "rgba(255, 148, 77, 0.2)",
      percent: ((props.data[2] / allNum.value) * 100).toFixed(0),
      val: props.data[2],
      unit: "座",
      title: "检修",
    },
    {
      strokeColor: "rgba(100, 107, 254, 1)",
      trailColor: "rgba(100, 107, 254, 0.2)",
      percent: ((props.data[3] / allNum.value) * 100).toFixed(0),
      val: props.data[3],
      unit: "座",
      title: "退运",
    },
    {
      strokeColor: "rgba(167, 167, 167, 1)",
      trailColor: "rgba(167, 167, 167, 0.2)",
      percent: ((props.data[4] / allNum.value) * 100).toFixed(0),
      val: props.data[4],
      unit: "座",
      title: "停运",
    },
  ];
});
</script>

<style lang="less" scoped>
.content-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      width: 81.58px;
      height: 72px;
      background-size: 100% 100%;
      position: relative;
      .text {
        position: absolute;
        top: 56%;
        left: 50%;
        color: rgba(224, 240, 255, 0.8);
        font-size: 10.5px;
        transform: translate(-50%, -50%);
        > div {
          text-align: center;
        }
        .percent {
          font-family: Alibaba Sans;
          font-size: 20px;
          font-weight: bold;
          margin-top: 2px;
          line-height: 26.14px;
        }
      }
      /deep/.ant-progress-inner {
        width: 81.58px !important;
        height: 81.58px !important;
      }
      /deep/.ant-progress-text {
        display: none;
      }
    }
    .bg1 {
      background-image: url("~@/assets/images/1bg.png");
    }
    .bg2 {
      background-image: url("~@/assets/images/2bg.png");
    }
    .bg3 {
      background-image: url("~@/assets/images/3bg.png");
    }
    .bg4 {
      background-image: url("~@/assets/images/4bg.png");
    }
    .bg5 {
      background-image: url("~@/assets/images/5bg.png");
    }
    .bot {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 600;
      margin-top: 16px;
    }
  }
}
</style>
