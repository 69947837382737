import { createApp } from "vue";
import "reflect-metadata";
import "element-plus/dist/index.css";
import Antd from "ant-design-vue";
import VueCountTo from "@/components/VueCountTo/index";
import "./assets/icons/index.js";
import SvgIcon from "./components/SvgIcon/SvgIcon.vue";
import IconBox from "./components/IconBox/IconBox.vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 新增代码：引入全部组件及样式
import "ant-design-vue/dist/reset.css";
import "@/assets/style/global.less";
import "echarts-gl";
createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  .component("CountTo", VueCountTo)
  .component("SvgIcon", SvgIcon)
  .component("IconBox", IconBox)
  .mount("#app");
