<template>
  <svg :class="svgClass" :style="props.svgStyle" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>
<script setup>
import { defineProps, computed } from "vue";
const props = defineProps({
  iconClass: {
    type: String,
    required: "",
  },
  className: {
    type: String,
    default: "",
  },
  svgStyle: {
    type: String,
    default: "",
  },
});
const iconName = computed(() => {
  return `#icon-${props.iconClass}`;
});
const svgClass = computed(() => {
  return props.className ? "svg-icon " + props.className : "svg-icon";
});
</script>

<style scoped>
.svg-icon {
  position: relative;
  fill: currentColor;
  overflow: hidden;
}
</style>
