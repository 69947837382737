<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ScreenLayout>
    <div class="screen-container">
      <div class="left-box">
        <Cardbox title="资源情况">
          <ResourceSituation :data="resourceSituationData"></ResourceSituation>
        </Cardbox>
        <Cardbox title="充电枪状态">
          <ChargingGunStatus :data="chargingGunStatusData"></ChargingGunStatus>
        </Cardbox>
        <Cardbox title="电站分布情况(座)">
          <PowerStation :data="powerStationData"></PowerStation>
        </Cardbox>
        <Cardbox title="运营状态分布">
          <OperatingState :data="operatingStateData"></OperatingState>
        </Cardbox>
      </div>
      <div class="middle-box">
        <MainMap></MainMap>
      </div>
      <div class="right-box">
        <Cardbox
          title="经营概况"
          :style="{
            height: '473px',
          }"
        >
          <ManagementProfile></ManagementProfile>
        </Cardbox>
        <Cardbox
          title="运维效率"
          :style="{
            height: '473px',
          }"
        >
          <MaintenanceEfficiency></MaintenanceEfficiency>
        </Cardbox>
      </div>
    </div>
  </ScreenLayout>
</template>

<script setup>
import MainMap from "./components/mainMap.vue";
import ScreenLayout from "@/components/ScreenLayout/index.vue";
import Cardbox from "@/components/Cardbox.vue";
import ResourceSituation from "./components/ResourceSituation.vue";
import ChargingGunStatus from "./components/ChargingGunStatus.vue";
import PowerStation from "./components/PowerStation.vue";
import OperatingState from "./components/OperatingState.vue";
import ManagementProfile from "./components/ManagementProfile.vue";
import MaintenanceEfficiency from "./components/MaintenanceEfficiency";
import { onMounted, ref, onUnmounted } from "vue";
import { getOverview } from "@/api/index.js";
const resourceSituationData = ref([0, 0, 0, 0]);
const chargingGunStatusData = ref([0, 0, 0, 0, 0, 0]);
const operatingStateData = ref([0, 0, 0, 0, 0]);
const powerStationData = ref([]);
const timer = ref(null);
onMounted(() => {
  queryData();
  timer.value = setInterval(() => {
    queryData();
  }, 10000);
});
async function queryData() {
  const [res, err] = await getOverview();
  if (err) return;
  if (res?.code === "10000") {
    const {
      stationsNum = 0,
      pilesNum = 0,
      directCurrentPilesNum = 0,
      exchangePilesNum = 0,
      chargingGunsNum = 0,
      standByGunsNum = 0,
      failureGunsNum = 0,
      offlineGunsNum = 0,
      insertGunsNum = 0,
      completedGunsNum = 0,
      inOperationStationNum = 0,
      constructionStationNum = 0,
      overhaulStationNum = 0,
      returnShipmentStationNum = 0,
      outageStationNum = 0,
    } = res.data;
    //资源情况
    resourceSituationData.value = [
      stationsNum,
      pilesNum,
      directCurrentPilesNum,
      exchangePilesNum,
    ];
    // 充电枪状态
    chargingGunStatusData.value = [
      chargingGunsNum,
      standByGunsNum,
      failureGunsNum,
      offlineGunsNum,
      insertGunsNum,
      completedGunsNum,
    ];
    // 电站分布情况(座)
    powerStationData.value = res.data.stationAreaResponses;
    // 运营状态分布
    operatingStateData.value = [
      inOperationStationNum,
      constructionStationNum,
      overhaulStationNum,
      returnShipmentStationNum,
      outageStationNum,
    ];
  }
}
onUnmounted(() => {
  clearInterval(timer.value);
  timer.value = null;
});
</script>
<style lang="less" scoped>
/deep/.screen-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
