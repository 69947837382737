<template>
  <div class="content-wrap">
    <div class="icon-wrap">
      <div>
        <div class="top">
          <span>异常率</span>
          <span>{{ abnormalData[0] }}%</span>
        </div>
        <div class="bot">
          <div>
            <div class="val">{{ abnormalData[1] }}%</div>
            <div class="txt">日环比</div>
          </div>
          <div class="week">
            <div class="val">{{ abnormalData[2] }}%</div>
            <div class="txt">周同比</div>
          </div>
        </div>
      </div>
      <div>
        <div class="top">
          <span>设备在线率</span>
          <span>{{ equipmentData[0] }}%</span>
        </div>
        <div class="bot">
          <div>
            <div class="val">{{ equipmentData[1] }}%</div>
            <div class="txt">日环比</div>
          </div>
          <div class="week">
            <div class="val">{{ equipmentData[2] }}%</div>
            <div class="txt">周同比</div>
          </div>
        </div>
      </div>
    </div>
    <div ref="initChart" class="chart-box"></div>
  </div>
</template>

<script setup>
import * as echarts from "echarts";
import { ref, onMounted, onUnmounted, reactive } from "vue";
import { queryOperationEfficiencyt } from "@/api/index.js";
const initChart = ref();
const abnormalData = ref([0, 0, 0]);
const equipmentData = ref([0, 0, 0]);
const timer = ref(null);
const chartData = reactive({
  xAxis: [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ],
  yData1: [],
  yData2: [],
});
onMounted(() => {
  chartInit();
  queryData();
  timer.value = setInterval(() => {
    queryData();
  }, 10000);
});
const chartInit = () => {
  const wid = 10;
  const w1 = Math.sin(Math.PI / 6) * wid; //4
  const w2 = Math.sin(Math.PI / 3) * wid; // 6.8
  const snapHeight = wid / 2;
  const CubeLeft = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0,
    },
    buildPath: function (ctx, shape) {
      const xAxisPoint = shape.xAxisPoint;
      const c0 = [shape.x, shape.y];
      const c1 = [shape.x - w2, shape.y];
      const c2 = [shape.x - w2, xAxisPoint[1]];
      const c3 = [shape.x, xAxisPoint[1]];
      ctx
        .moveTo(c0[0], c0[1])
        .lineTo(c1[0], c1[1])
        .lineTo(c2[0], c2[1])
        .lineTo(c3[0], c3[1])
        .closePath();
    },
  });
  const CubeRight = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0,
    },
    buildPath: function (ctx, shape) {
      const xAxisPoint = shape.xAxisPoint;
      const c1 = [shape.x, shape.y];
      const c2 = [shape.x, xAxisPoint[1]];
      const c3 = [shape.x + w1, xAxisPoint[1] - w2 + snapHeight];
      const c4 = [shape.x + w1, shape.y - w2 + snapHeight];
      ctx
        .moveTo(c1[0], c1[1])
        .lineTo(c2[0], c2[1])
        .lineTo(c3[0], c3[1])
        .lineTo(c4[0], c4[1])
        .closePath();
    },
  });
  const CubeTop = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0,
    },
    buildPath: function (ctx, shape) {
      const c1 = [shape.x, shape.y];
      const c2 = [shape.x + w1, shape.y - w2 + snapHeight]; //右点
      const c3 = [shape.x - w2 + w1, shape.y - w2 + snapHeight];
      const c4 = [shape.x - w2, shape.y];
      ctx
        .moveTo(c1[0], c1[1])
        .lineTo(c2[0], c2[1])
        .lineTo(c3[0], c3[1])
        .lineTo(c4[0], c4[1])
        .closePath();
    },
  });
  echarts.graphic.registerShape("CubeLeft", CubeLeft);
  echarts.graphic.registerShape("CubeRight", CubeRight);
  echarts.graphic.registerShape("CubeTop", CubeTop);
  let option = {
    grid: {
      left: "5%",
      right: 0,
      bottom: "8%",
      top: "27%",
      containLabel: true,
    },
    legend: [
      {
        data: [
          {
            name: "设备在线率",
            icon: "rect",
          },
        ],
        right: 55,
        top: 34,
        itemHeight: 8,
        itemWidth: 8,
        textStyle: {
          fontSize: 10,
          color: "rgba(224, 240, 255, 0.8)",
        },
        itemStyle: {
          color: "#29F1FA",
          borderColor: "#29F1FA",
        },
      },
      {
        data: [
          {
            name: "异常率",
            icon: "rect",
          },
        ],
        right: 5,
        top: 34,
        itemHeight: 4,
        itemWidth: 4,
        textStyle: {
          fontSize: 10,
          color: "rgba(224, 240, 255, 0.8)",
        },
        itemStyle: {
          borderWidth: 4,
          color: "#D4C18A",
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(18, 26, 45, 0.9)",
      borderColor: "rgba(0, 221, 255, 1)",
      textStyle: {
        color: "#E0F0FF",
        fontSize: 12,
      },
      formatter: function (info) {
        let str = `<div style="width:170px;"><div style="text-align: left;color: #E0F0FF;font-size:16px;padding-left:6px;margin-bottom:8px;margin-top:4px" >${info[0].name}</div>`;
        info.forEach((item) => {
          str += `<div style="
                  height: 26px;
                  display: flex;
                  justify-content: space-between;
                  padding: 8px;
                  border-radius: 4px;
                  margin: 4px 0; align-items: center;"
        >
        <div style="
                  display: flex;
          align-items: center;
            ">
            <span style="width:12px;height:12px;border-radius:50%;display: inline-block; background:${
              item.borderColor ? item.borderColor : "#29F1FA"
            }"></span>
        <span style=" font-size: 14px;line-height:14px;margin-left:5px">${
          item.seriesName
        }</span>
          </div>
          <span style="color: ${
            item.borderColor ? item.borderColor : "#29F1FA"
          }; font-size: 16px;font-family: D-DIN;">${Number(item.value).toFixed(
            2
          )}%</span></div></div>`;
        });
        return str;
      },
    },
    xAxis: {
      type: "category",
      data: chartData.xAxis, //数据
      axisLabel: {
        color: "#fff",
        fontSize: 10,
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: "dashed", //线的类型 虚线
        },
      },
      axisTick: false,
    },
    yAxis: [
      {
        name: "",
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#565f6a",
            width: 1,
          },
        },
        axiosTick: {
          show: true,
        },
        axisLabel: {
          color: "#fff",
          fontSize: 10,
          formatter: function (value) {
            return `${value}%`;
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            width: 1,
            // 使用深浅的间隔色
            color: ["#566471", "#566471"],
          },
        },
      },
      {
        type: "value",
        name: "",
        min: 0,
        max: 100,
        nameTextStyle: {
          color: "#fff",
          fontSize: 18,
          padding: [0, 0, 10, 30],
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#84929f",
          },
        },
        axisLabel: {
          show: false,
          color: "#fff",
          fontSize: 10,
          formatter: function (value) {
            return `${value}%`;
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            type: "dashed",
            width: 1,
            // 使用深浅的间隔色
            color: ["#566471", "#566471"],
          },
        },
      },
    ],
    series: [
      {
        name: "异常率",
        type: "line",
        yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
        smooth: true,
        symbol: "circle", //标记的图形为实心圆
        symbolSize: 4, //标记的大小
        lineStyle: {
          color: "#FDE9A6", //线条颜色
        },
        itemStyle: {
          color: "#fff", // 圆点背景色
          borderColor: "#FDE9A6", //圆点透明 边框
          borderWidth: 2,
        },
        data: chartData.yData2,
      },
      {
        name: "设备在线率",
        type: "custom",
        renderItem: (params, api) => {
          const location = api.coord([api.value(0), api.value(1)]);
          return {
            type: "group",
            children: [
              {
                type: "CubeLeft",
                shape: {
                  api,
                  xValue: api.value(0),
                  yValue: api.value(1),
                  x: location[0],
                  y: location[1],
                  xAxisPoint: api.coord([api.value(0), 0]),
                },
                style: {
                  fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      //左侧面
                      offset: 0,
                      color: "rgba(30, 230, 231, 0.9)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0, 129, 206, 0.5)",
                    },
                  ]),
                },
              },
              {
                type: "CubeRight",
                shape: {
                  api,
                  xValue: api.value(0),
                  yValue: api.value(1),
                  x: location[0],
                  y: location[1],
                  xAxisPoint: api.coord([api.value(0), 0]),
                },
                style: {
                  fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(41, 241, 250, 0.9)",
                    },
                    {
                      offset: 1,
                      color: "rgba(24, 162, 199, 0.5)",
                    },
                  ]),
                },
              },
              {
                type: "CubeTop",
                shape: {
                  api,
                  xValue: api.value(0),
                  yValue: api.value(1),
                  x: location[0],
                  y: location[1],
                  xAxisPoint: api.coord([api.value(0), 0]),
                },
                style: {
                  fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#29F1FA",
                    },
                    {
                      offset: 1,
                      color: "#29F1FA",
                    },
                  ]),
                },
              },
            ],
          };
        },
        data: chartData.yData1,
      },
    ],
  };
  echarts.init(initChart.value).dispose();
  echarts.init(initChart.value).setOption(option);
};
async function queryData() {
  const [res, err] = await queryOperationEfficiencyt();
  if (err) return;
  if (res?.code === "10000") {
    const {
      //异常率
      abnormalRate = 0,
      abnormalRateChain = 0,
      abnormalRateCompared = 0,
      // 设备在线率
      equipmentOnlineRate = 0,
      equipmentOnlineRateChain = 0,
      equipmentOnlineRateCompared = 0,
      dateList = [],
      abnormalRateList = [],
      equipmentOnlineRateList = [],
    } = res.data;
    abnormalData.value = [
      abnormalRate ? Number(abnormalRate).toFixed(0) : 0,
      abnormalRateChain ? Number(abnormalRateChain).toFixed(0) : 0,
      abnormalRateCompared ? Number(abnormalRateCompared).toFixed(0) : 0,
    ];
    equipmentData.value = [
      equipmentOnlineRate ? Number(equipmentOnlineRate).toFixed(0) : 0,
      equipmentOnlineRateChain
        ? Number(equipmentOnlineRateChain).toFixed(0)
        : 0,
      equipmentOnlineRateCompared
        ? Number(equipmentOnlineRateCompared).toFixed(0)
        : 0,
    ];
    chartData.xAxis = dateList;
    chartData.yData1 = equipmentOnlineRateList;
    chartData.yData2 = abnormalRateList;
    chartInit();
  }
}
onUnmounted(() => {
  clearInterval(timer.value);
  timer.value = null;
});
</script>

<style lang="less" scoped>
.content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .icon-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    > div {
      width: 192px;
      height: 128px;
      margin: 0 14px;
      background-image: url("~@/assets/images/iconbg.png");
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .top {
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: AlibabaPuHuiTi;
        font-size: 15.26px;
        font-weight: 500;
        line-height: 22.89px;
        letter-spacing: 0em;
        /* 一级文字-100% */
        color: #e0f0ff;
        > span {
          &:nth-of-type(1) {
            margin-right: 10px;
          }
        }
      }
      .bot {
        display: flex;
        height: 82px;
        justify-content: center;
        align-items: center;
        > div {
          text-align: center;
          margin: 0 12px;
          .txt {
            font-family: AlibabaPuHuiTi;
            font-size: 13.35px;
            /* 二级文字-80% */
            color: rgba(224, 240, 255, 0.8);
            margin-top: 6px;
          }
          .val {
            font-family: Alimama ShuHeiTi;
            font-size: 21px;
            font-weight: bold;
            line-height: 22.89px;
            letter-spacing: 0px;
            /* 辅助-蓝 */
            color: #69b7ff;
          }
        }
        .week .val {
          color: #ff944d;
        }
      }
    }
  }
  .chart-box {
    height: 300px;
    width: 100%;
  }
}
</style>
