<template>
  <div class="data-screen">
    <Container>
      <router-view />
    </Container>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Container from "@/components/Container";
export default defineComponent({
  components: {
    Container,
  },
});
</script>
<style lang="less">
#app,
html,
body,
#app,
.data-screen {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #000;
}

.data-screen {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg {
  width: 100%;
  height: 100%;
}

div,
section {
  margin: 0;
  padding: 0;
}
</style>
